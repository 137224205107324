<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/applyfor/banner2.jpg" alt="" />
    </div>
    <div class="container">
      <div class="alert_box">
        <el-alert
          title="已注册用户请点击右上角立即登录按钮，登录后进行申请"
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>

      <div class="box">
        <div class="step_box">
          <el-steps :active="1" align-center>
            <el-step title="申请并注册"></el-step>
            <el-step title="上传资料"></el-step>
            <el-step title="提交成功"></el-step>
          </el-steps>
        </div>

        <div class="form_box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="140px"
            label-position="left"
            class="demo-ruleForm"
          >
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input
                v-model="ruleForm.enterpriseName"
                placeholder="请输入企业名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码" prop="socialCode">
              <el-input
                v-model="ruleForm.socialCode"
                placeholder="请输入统一社会信用代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业规模" prop="companySize">
              <el-select v-model="ruleForm.companySize" clearable placeholder="请选择企业规模">
                <el-option
                  v-for="item in entSizeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司成立日期" required>
              <el-form-item prop="date">
                <el-date-picker
                  type="date"
                  placeholder="选择公司成立日期"
                  v-model="ruleForm.date"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  @change="dateChange"
                ></el-date-picker>
              </el-form-item>
            </el-form-item>
            <el-form-item label="联系人" prop="contactUser">
              <el-input
                v-model="ruleForm.contactUser"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="ruleForm.phone"
                maxlength="11"
                placeholder="请输入联系电话"
              ></el-input>
              <div class="prompt">联系电话即为注册平台手机号</div>
            </el-form-item>
            <!-- <el-form-item label="推荐人手机号" prop="contactUser">
              <el-input
                v-model="ruleForm.refereeNum"
                placeholder="请输入推荐人手机号"
              ></el-input>
            </el-form-item> -->
            <el-form-item prop="captcha" label="图形验证码">
              <el-col :span="16">
                <el-input
                  v-model="ruleForm.captcha"
                  auto-complete="off"
                  placeholder="图形验证码"
                >
                </el-input>
              </el-col>
              <el-col :span="4" :offset="1">
                <div class="login-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img" />
                </div>
              </el-col>
            </el-form-item>
            <el-form-item label="短信验证码" prop="smsCode">
              <el-col :span="16">
                <el-input
                  v-model="ruleForm.smsCode"
                  placeholder="请输入短信验证码"
                  maxlength="6"
                ></el-input>
              </el-col>
              <el-col :span="4" :offset="1">
                <el-button
                  type="text"
                  :disabled="dis"
                  v-if="isShowGetCode"
                  @click="getSMScode"
                  >获取验证码</el-button
                >
                <el-button
                  type="text"
                  @click="getSMScode"
                  :disabled="dis"
                  v-else
                  >{{ count }}s后重发</el-button
                >
              </el-col>
            </el-form-item>

            <el-form-item class="submit1">
              <div class="tips">
                <el-checkbox v-model="agreement"></el-checkbox>
                我已阅读并同意<a
                  href="/html/register_xiyi.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  >《数电通中小微企业服务生态平台注册协议》</a
                >、
                <a
                  href="/html/service_xiyi.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  >《服务协议》</a
                >、
                <a
                  href="/html/privacy_xiyi"
                  target="_blank"
                  rel="noopener noreferrer"
                  >《隐私声明》</a
                >。
              </div>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >立即申请并注册</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  registerApi,
  getCodeImg,
  getSMScode,
  addQualification,
} from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      ruleForm: {
        enterpriseName: "",
        socialCode: "",
        phone: "",
        captcha: "",
        date: "",
        smsCode: "",
        uuid: "",
        smsUuid: "",
        contactUser: "",
        refereeNum: "",
        registeredChannel: "GW",
        companySize: "",
      },
      // 成立日期是否大于90天
      timegap: "",
      idCardFirst: [],
      uploadImgUrl: "",
      headers: {
        Authorization: "",
      },
      entSizeOptions: [
        { value: "1", label: "一般纳税人" },
        { value: "2", label: "小规模纳税人" },
      ],
      rules: {
        contactUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        socialCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        companySize: [
          { required: true, message: "请选择企业规模", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        smsCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        date: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
      isShowGetCode: true,
      dis: false, //是否禁用按钮
      timer: null, //设置计时器,
      count: "", //倒计时
      codeUrl: "",
      captchaEnabled: "",
      agreement: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.data.img;
        this.ruleForm.uuid = res.data.uuid;
      });
    },
    // 获取短信验证码
    getSMScode() {
      this.$refs["ruleForm"].validateField("phone", (phone) => {
        if (!phone) {
          this.$refs["ruleForm"].validateField("captcha", (captcha) => {
            if (!captcha) {
              this.getPhoneCode();
            }
          });
        }
      });
    },
    getPhoneCode() {
      let data = {
        enterpriseName: this.ruleForm.enterpriseName,
        socialCode: this.ruleForm.socialCode,
        phone: this.ruleForm.phone,
        captcha: this.ruleForm.captcha,
        date: this.ruleForm.date,
        smsCode: this.ruleForm.smsCode,
        uuid: this.ruleForm.uuid,
        username: this.ruleForm.phone,
      };
      getSMScode(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("发送短信验证码成功");
            this.ruleForm.smsUuid = res.data.data.uuid;
            let TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.isShowGetCode = false;
              this.dis = true;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.dis = false;
                  this.isShowGetCode = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.getCode(); // 获取图形验证码
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          this.getCode(); // 获取图形验证码
        });
    },
    dateChange() {
      let establishDate = new Date(this.ruleForm.date);
      let now = new Date();
      let establishTime = establishDate.getTime();
      let nowTime = now.getTime();
      let timegaptemp = Math.round(
        (nowTime - establishTime) / 1000 / 60 / 60 / 24
      );
      this.timegap = timegaptemp;
      sessionStorage.setItem("buildDate", this.timegap);
    },
    submitForm(formName) {
      if (this.agreement) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {
              customerName: this.ruleForm.enterpriseName,
              socialCreditCode: this.ruleForm.socialCode,
              contactUser: this.ruleForm.contactUser,
              contactTelephone: this.ruleForm.phone,
              customerCreateTime: this.ruleForm.date,
              captcha: this.ruleForm.captcha,
              smsCaptcha: this.ruleForm.smsCode,
              uuid: this.ruleForm.smsUuid,
              registeredChannel: this.ruleForm.registeredChannel,
              refereeNum: this.ruleForm.refereeNum,
              phoneNumber: this.ruleForm.phone,
              companySize: this.ruleForm.companySize,
            };
            registerApi(data).then((res) => {
              if (res.data.code == 200) {
                this.headers.Authorization = res.data.data.access_token;
                sessionStorage.setItem("token", res.data.data.access_token);
                this.$message.success(res.data.msg);
                // this.dialogSuccess = true;
                this.$router.push({
                  path: "/uploadform?id=" + res.data.data.bookkeepingAgencyProcess.id + "&processCode=" + res.data.data.bookkeepingAgencyProcess.processCode,
                });
              } else {
                this.$message.success(res.data.msg);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$message.error("请先同意协议");
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.uploadImgUrl = "/prod-api/file/upload";
    if (sessionStorage.getItem("token")) {
      this.headers.Authorization = sessionStorage.getItem("token");
    }
    // if (this.$route.query.refereeNum) {
    //   this.ruleForm.refereeNum = this.$route.query.refereeNum;
    // }
    this.getCode();
  },
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.alert_box {
  width: 900px;
  margin: 0 auto 30px;
}

.form_box {
  width: 800px;
  margin: 0 auto;
}

.login-code {
  width: 100%;
  height: 38px;
}

.login-code img {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;
}

.submit1 {
  margin-top: 30px;
  text-align: center;
}

.submit1 .el-button {
  width: 200px;
}

.tips a {
  color: #409eff;
}

.step_box {
  margin: 0 auto 30px;
}

.prompt {
  font-size: 12px;
  color: #f56c6c;
}
</style>
